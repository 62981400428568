
import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";


import {CKEditor} from 'ckeditor4-react';

import { toast } from "react-toastify";

import AudioImg from '../../assets/img/AudioImg.jpg'
import {VideoTest, addnewpromotion,getcms1,getnewpromotion} from '../../actions/users';
import config from "../../lib/config"
import isEmpty from "lib/isEmpty";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactHTMLParser from 'react-html-parser';
import Button from "components/CustomButtons/Button.js";


// const styles = {
//   cardCategoryWhite: {
//     color: "rgba(255,255,255,.62)",
//     margin: "0",
//     fontSize: "14px",
//     marginTop: "0",
//     marginBottom: "0"
//   },
//   cardTitleWhite: {
//     color: "#FFFFFF",
//     marginTop: "0px",
//     minHeight: "auto",
//     fontWeight: "300",
//     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//     marginBottom: "3px",
//     textDecoration: "none"
//   }
// };

// const useStyles = makeStyles(styles);

export default function NewPromotion(props) {
    const [imgfilename, setimgfilename] = useState('');
    const [fileSizes, setfilesize] = useState(0)
    const [TokenFile, setTokenFile] = useState("");
    const [TokenFilePreUrl, setTokenFilePreUrl] = useState("");
    const [TokenFilePreReader, setTokenFilePreReader] = useState("");
    const [imgpath, setimgpath] = useState("");
    const [answer, setanswer] = useState("");
    const [question, setquestion] = useState("");
    const [validateError, setValidateError] = useState({});
    // const classes = useStyles();
    const [profile, setProfile] = useState({});
    


useEffect(()=>
{
    getInit()
},[])

const getInit = async()=>
{
    var data = await getnewpromotion()
    var promotion = data?.data?.data;
    console.log("sdfbsfgbdfgbdfg",promotion?.promotion[0]);
    var file = promotion?.promotion[0]?.image
    var path = `${config.Back_Url}/newpromotion/${file}`
    console.log('kdjfksdjgksdgs',path,promotion,data)
    //console.log("gfndghnfghn",imgpath);

    //console.log("ghmnfghmhgfm",path);
    setimgfilename(file)
    setimgpath(path)

    setProfile({
      creator: promotion?.promotion[0]?.creator,
      TokenName: promotion?.promotion[0]?.TokenName,
      Profile: promotion?.promotion[0]?.Profile,
      id: promotion?.promotion[0]?._id
    })

    //    CMS
    var test = data?.data?.data;
    console.log('ldksjfjsfs',test)
    if(test&&test.cms){
      if(test.cms.question){
    // formdata['question'] = test.cms.question;
        setquestion(test.cms.question)
  }
    if(test.cms.answer){
    // formdata['answer'] = test.cms.answer;
    // alert(test.cms.answer)
      setanswer(test.cms.answer)
  }
    }
    
}

  const selectFileChange = async(e) => {
    setValidateError({})
    if (e.target.name == 'file') {
      setimgpath("")
      setimgfilename("")
      var validExtensions = ["png", 'gif', 'webp', 'mp4', 'PNG', 'jpg', 'JPEG', 'jpeg', 'JPG', 'mp3', 'aac', 'AAC', 'flac', 'FLAC', 'WEBM', 'webm', 'ogv', 'OGV']; //array of valid extensions
      if (e.target && e.target.files) {
        var reader = new FileReader()
        var file = e.target.files[0];
        const { value } = e.target;
        console.log("dakldjasdkasjdjasd",e.target, file)
        if(file.type.includes('video')){
          var test = await VideoTest(file);
          console.log('kkkkkkkk',test)
          if(test?.data?.data){
            if(test?.data?.data?.height == 1080 && test?.data?.data?.width == 1920 ){

            }
            else{
              toast.error('Uploade Video must be 1080*1920 format')
              return false;
            }
          }
          else{
            toast.error('Try-Again')
            return false;
          }
        }
        setimgfilename(file.name)

        var fileName = file.name;
        var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
        if ($.inArray(fileNameExt, validExtensions) === -1) {
          toast.error("Only these file types are accepted : " + validExtensions.join(', '));
          return false;
        }
        const fileSize = file.size;
        if (30000000 < fileSize) {
          toast.error("File size exceeds 30 MB");
          return false;
        }
        else {
          //console.log("fileSize,file", fileSize, file);
          setfilesize(fileSize)
          //console.log("dghnfgjngdhj",file);
          setTokenFile(file);
          var url = reader.readAsDataURL(file);
          setTokenFilePreUrl(value);
          reader.onloadend = function (e) {
            if (reader.result) {

              //console.log("reader.result", reader.result);
              setTokenFilePreReader(reader.result);
            }
          }.bind(this);
          console.log("e.target.value",value ,e.target, e.target?.value);
          
        }
      }
    }
    else if(e.target.name == 'profile' ){
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      var validExtensions = ["png", 'webp', 'PNG', 'jpg', 'JPEG', 'jpeg', 'JPG']; //array of valid extensions
      if ($.inArray(fileNameExt, validExtensions) === -1) {
        toast.error("Only these file types are accepted : " + validExtensions);
        return false;
      }
      setProfile({...profile,...{Profile:file}});
    }
  }
 
      const Oncancel = async(from)=>
      {
        if(from=='profile'){
          setProfile({...profile,...{Profile:undefined}})
          toast.success("Cancelled successfully")
        }
        else{
          setimgfilename('')
          setfilesize(0)
          setTokenFile('')
          setTokenFilePreReader('')
          setTokenFilePreUrl('')
          toast.success("Cancelled successfully")
        }
        
      }

const Onsubmit = async()=>
{
    var addproimg = await addnewpromotion({image:TokenFile})
    //console.log("fgjnfhgmjfghm",addproimg);
    if(addproimg.data.success===true)
    {
        toast.success("successfully uploaded")
    }
}
// http://localhost:2000/public/newpromotion/jpg1681106190541


const onEditorChange = (evt) => {
  var description_text = evt.editor.getData() 
  console.log('djdgdgdgd',description_text,evt)
  if(description_text == ''){
    setanswer(' ')
  }
  else{
    if(description_text[0]==' '){
      setanswer(description_text.substring(1))
    }
    else{
      setanswer(description_text)
    }
  }
  //console.log("setanswer",description_text)
  
}

const Validation = () => {
  const {creator, TokenName, Profile } = profile;
  console.log('sjfksjfs',typeof(Profile),Profile)
  let error = {};
  if(isEmpty(creator)){
    error.creator = "Enter Creator Name";
  }
  if(isEmpty(TokenName)){
    error.TokenName = "Enter Token Name";
  }
  if(typeof(Profile) == 'undefined'){ 
    error.Profile = "Upload Profile Image";
  }
  if(isEmpty(TokenFilePreUrl) && isEmpty(imgfilename)){
    error.image = "Upload File";
  }
  return error;
}

const handleFormSubmit = async() => {
  var validate = Validation();
  console.log('ksfsfsfs',validate)
  if (isEmpty(validate)) {
    console.log('mmsmfskfsf',answer);
    // var crtanswer = answer.
    var formData = new FormData();
    formData.append('image', TokenFile);
    formData.append('creator', profile?.creator);
    formData.append('TokenName', profile?.TokenName);
    formData.append('Profile', profile?.Profile);
    formData.append('id', profile?.id);
    formData.append('question', question);
    formData.append('answer',answer);

    var addproimg = await addnewpromotion(formData)
    console.log("fgjnfhgmjfghm",addproimg);
    if (addproimg?.data?.success === true) {
      toast.success("successfully uploaded")
    }
  }
  else{
    setValidateError(validate)
  }
}

 const OnChange = (e) => {
  setValidateError({})
    setProfile({...profile,...{[e.target.id]:e.target.value}})
 }

  return (
    <>
    {console.log('fsjfkjsfs',validateError)}
    <div>
    {/* <label className="primary_label" htmlFor="inputEmail4">Enter Token Name : </label> */}
<GridContainer>
    <GridItem xs={12} sm={12} md={5}>
					<CustomInput
					labelText="Token Name"
					value={profile?.TokenName || ''} id="TokenName"
					onChange={(e)=>OnChange(e)}
					
					formControlProps={{
						fullWidth: true
					}}
					// inputProps={{
					//   disabled: true
					// }}
          />
          </GridItem>
          </GridContainer>
    
    {/* <input type="text" placeholder="TokenName" value={''||profile?.TokenName} id="TokenName" onChange={(e)=>OnChange(e)}/><br/> */}
    {
      validateError.TokenName && <span className="validation_txt">{validateError.TokenName}</span>
    }
  </div>
  {console.log('skjfksjfs',profile,isEmpty(profile?.Profile),typeof(profile.Profile))}
  <div>
  <GridContainer>
    <GridItem xs={12} sm={12} md={5}>
					<CustomInput
					labelText="Creator"
					value={profile?.creator || ''} id="creator"
					onChange={(e)=>OnChange(e)}
					
					formControlProps={{
						fullWidth: true
					}}
					// inputProps={{
					//   disabled: true
					// }}
          />
          </GridItem>
          </GridContainer>
          {
      validateError.creator && <span className="validation_txt">{validateError.creator}</span>
    }
    </div>
  {/* <div>
    <label className="primary_label" htmlFor="inputEmail4">Enter Creator Name : </label>
    
    <input type="text" placeholder="Creator" id="creator" value={''||profile?.creator} onChange={(e)=>OnChange(e)}/><br/>
    {
      validateError.creator && <span className={classes.textDanger}>{validateError.creator}</span>
    }
  </div> */}
  <div>
    <label className="primary_label mt-3" htmlFor="inputEmail4">Creator Profile : </label>
    
  </div>
  
    <input
      className="inp_file"
      type="file"
      name="profile"
      accept="audio/*,video/*,image/*"
      onChange={selectFileChange}
    />
    
      <button className="btn btn-danger" onClick={()=>Oncancel('profile')}>Cancel</button><br/>
    
    
    

  {
      validateError.Profile && <span className="validation_txt">{validateError.Profile}</span>
    }
  <div className="remaintime mt-3">
  <label className="primary_label">Creator Profile Preview</label>
                      <div className="item_inner_img">
{typeof(profile?.Profile) == 'string' ?
    (<img src={isEmpty(profile?.Profile) ? require('../../assets/img/noimage.png') : `${config.Back_Url}/newpromotion/${profile?.Profile}` } id="imgPreview" alt="Collections" className="img-fluid" />)
    :
    (
      <img src={ typeof(profile?.Profile) == 'object' ? URL.createObjectURL(profile?.Profile) : require('../../assets/img/noimage.png') } id="imgPreview" alt="Collections" className="img-fluid" />
    )
}
                      </div>
                    </div>
    <div>
    <label className="primary_label mt-3" htmlFor="inputEmail4">Upload file</label>
    <p className="form_note mt-1">JPG, JPEG, PNG, GIF, WEBP, WEPM, OGV, MP3, FLAC, AAC or MP4. Max 30 megabytes
    </p> 
  </div>
  <div className="upload">
    <p>Upload</p>
  </div>
  <div className="file_btn btn primary_btn">
    <input
      className="inp_file"
      type="file"
      name="file"
      accept="audio/*,video/*,image/*"
      onChange={selectFileChange}
    />
  </div>
  <div className="file_btn btn primary_btn" onClick={()=>Oncancel()}>
    <button className="btn btn-danger">Cancel</button>
  </div>
  {/* {TokenFile!=""&&
  <div className="file_btn btn primary_btn" onClick={Onsubmit}>
    <button className="btn btn-success">Upload</button>
  </div>
} */}
  <div className="remaintime mt-3">
  <label className="primary_label">Preview</label>
                      <div className="item_inner_img">

{
TokenFilePreUrl!==""&&["mp4", "webm", "WEBM", "OGV", "ogv"].includes(TokenFilePreUrl?.split('.')?.pop())||imgfilename!==""&&["mp4", "webm", "WEBM", "OGV", "ogv"].includes(imgfilename?.split('.')?.pop()) ?
    (<video
        id="imgPreview"
        className="img-fluid"
        alt="img"
        autoPlay
        controls
        muted
        playsInline
        loop
        src={TokenFilePreReader !== "" ? TokenFilePreReader : imgpath !== ""
        ? imgpath:  require('../../assets/img/noimage.png')}
        type="video/mp4"
     />)
    :
    (imgpath === ""&&imgfilename===undefined&&TokenFilePreReader===""?
        (<video
            id="imgPreview"
            className="img-fluid"
            alt="img"
            autoPlay
            controls
            muted
            playsInline
            loop
            src={imgpath}
            type="video/mp4"
         />)
        :
        null
    )
}
{/* <audio controls autoplay>
  <source src="horse.ogg" type="audio/ogg" />
  <source src="horse.mp3" type="audio/mpeg" />
Your browser does not support the audio element.
</audio> */}

{TokenFilePreUrl.split('.').pop() === 'mp3' || imgpath.split('.').pop() === 'mp3'? (
  <>
    <img src={AudioImg} className="img-fluid" />

    <audio
      controls
      autoplay
      src={
        TokenFilePreReader !== ""
          ? TokenFilePreReader
          : require('../../assets/img/noimage.png')
      }
    >
      {/* <source src={TokenFilePreReader !== ""
          ? TokenFilePreReader
          : require('../../assets/img/noimage.png')} type="audio/mp3"/> */}
    </audio>
    
  </>
) :imgpath === ""&&imgfilename===undefined&& imgpath.split('.').pop() === 'mp3' ? (
  <>
    <img src={AudioImg} className="img-fluid" />

    <audio
      muted
      id="imgPreview"
      className="img-fluid"
      alt="img"
      autoPlay
      controls
      playsInline
      loop
      src={
        TokenFilePreReader !== ""
          ? TokenFilePreReader
          : imgpath !== ""
          ? imgpath
          : require('../../assets/img/noimage.png')
      }
      type="audio/mp3"
    ></audio>
  </>
) : null}


{["webp", "WEBP", "gif", "jpg", "GIF", "JPG", "JPEG", "jpeg", "png", "PNG"].includes(TokenFilePreUrl?.split('.')?.pop()) ?
    (<img src={TokenFilePreReader !== "" ? TokenFilePreReader :  require('../../assets/img/noimage.png')} id="imgPreview" alt="Collections" className="img-fluid" />)
    :
    (imgpath !== ""&&imgfilename!=undefined &&TokenFilePreReader===""&& ["webp", "WEBP", "gif", "jpg", "GIF", "JPG", "JPEG", "jpeg", "png", "PNG"].includes(imgfilename?.split('.')?.pop()) ?
        (<img src={imgpath} id="imgPreview" alt="Collections" className="img-fluid" />)
        :
        null
    )
}

                        {TokenFilePreReader === "" &&(imgfilename === ""||imgfilename === undefined)&&
                          <img src={require('../../assets/img/noimage.png')} id="imgPreview" alt="Collections" className="img-fluid" />
                        }


                      </div>
                    </div>
                    <div>
      <GridContainer>
        <GridItem  xs={12} sm={12} md={12}>
          <Card>
            <form >
              <CardHeader color="primary">
                <h4>EDIT CMS</h4>
             
              </CardHeader>
              <CardBody>
                <GridContainer>                 
                    <GridItem xs={12} sm={12} md={3}>
                    </GridItem>
                    
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                    {answer&&
                      <CKEditor
                            // value={answer}
                            // data={}
                            initData={ReactHTMLParser(answer)}
                            id="answer"
                            onChange={onEditorChange}
                        />
                    } 
                   
                    {
                      validateError.answer && <span>{validateError.answer}</span>
                    }
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="button" onClick={()=>handleFormSubmit()}>Update</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>       
      </GridContainer>
    </div>
  </>

  );
}







