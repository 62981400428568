import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import isEmpty from '../../lib/isEmpty';
import {  Subcategory , addcategory } from '../../actions/users';
import ABI from '../../ABI/ABI.json';
import Fortmatic from 'fortmatic';
import config from '../../lib/config'
import Web3 from 'web3';
import '@metamask/legacy-web3';
const { web3 }      = window;
const Smartcontract = config.Smartcontract;
const OwnerAddr     = config.OwnerAddr;
const useStyles     = makeStyles(styles);
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

const initialFormValue = {
  'name': "",
}

export default function ViewCategory() {
  const classes                   = useStyles();
  const history                   = useHistory();
  const dispatch                  = useDispatch();
  const [userdet, setUser]        = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [accounts, setaccount]     = React.useState(0);
  const [tokenbalance, setTokenbalance]      = React.useState(0);
  const [bnbbalance, setBNBbalance]= React.useState(0);
  const [category,setCategory] = useState({});
  const [number,setNumber] = useState([0]);
  const [subcategory,setSubCategory] = useState({});
  const { id } = useParams();

  const handleFile = (event) => {
    event.preventDefault();
    const { id, files } = event.target;
    let formData = { ...formValue, ...{ [id]: files[0] } }
    setFormValue(formData)
  };

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setCategory({...category,...{"name":value}})
  }

    const {
        name
    } = formValue

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    var error = Validate();
    //console.log("error",error,category,subcategory)
      if (isEmpty(error)) {
        var arr = [];
        number.map((val)=>{
            arr.push(subcategory[val]);
        })
        //console.log('vallll',arr)
        var send=category;
        //console.log('chhheecckk',send);
        send.subcategory = arr;
        //console.log('chhheecckk1111',send);
        send.action = 'edit';
        var resp =await Subcategory(send);
        //console.log('ressssppp',resp)
        if(resp?.data?.success){
            toast.success('Category details added', toasterOption);
            setTimeout(()=>{
                window.location.href="/ecafade/categorylist"
            },1000)
        }
        else{
            toast.error('Category not added', toasterOption);
        }
    } else {
      setValidateError(error);
    }
   
  }

  useEffect(()=>{
    GetCategory()
  },[])

  var GetCategory = async() =>{
    var resp =await Subcategory({id:id,action:"get"});
    //console.log('hiiiiii',resp);
    setCategory(resp.data.list);
    var sub = {};
    resp.data.list.subcategory.map((val,ind)=>{
        sub[ind]=val;
    });
    setSubCategory(sub);
    setNumber(Object.keys(sub))
  }

  var onsubChange = (e) =>{
    const { id, value } = e.target;
    setSubCategory({...subcategory,...{[id]:value}})
  }

  var Validate = () =>{
    var error = {};
    if(isEmpty(category.name)){
        error.name = "Category Name Required";
    }
    number.map((val)=>{
        if(isEmpty(subcategory[val])){
            error["subcategor"+val] = "Sub-Category Required"
        }
    })
    return error;
  }

  var Deletecall = async(val) =>{
    var resp =await Subcategory({_id:category._id,index:val,action:"delete"});
    //console.log('ressspppp',resp)
    if(resp.data.success){
        toast.success('Sub-Category deleted')
        setTimeout(()=>{
            window.location.href="/ecafade/Viewcategory/"+category._id;
        },1000)
        
    }
    else{
        toast.error('Sub-Category not deleted', toasterOption);
    }
  }
  
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Edit Category</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>                 
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Category name"
                      onChange={onChange}
                      id="name"
                      value={category.name || ''}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {
                        validateError.name && <span className="text-danger">{validateError.name}</span>
                    }
                  </GridItem>
                  
                </GridContainer>
                <Button color="primary" type="button" onClick={()=>{setNumber([...number,...[number.length]])}}>Add</Button>
                {number?.length > 0 &&
                (number?.map((val,ind)=>

                
                <GridContainer> 
                    <GridItem xs={12} sm={12} md={3}>
                    
                    <CustomInput
                      labelText="Sub-Category name"
                      onChange={onsubChange}
                      id={ind}
                      value={subcategory[ind] || ''}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <i className="fa fa-trash" onClick={()=>{Deletecall(ind)}}></i>
                    {
                        validateError['subcategor'+ind] && <span className="text-danger">{validateError['subcategor'+ind]}</span>
                    }
                  </GridItem>
                </GridContainer>
                ))
                }
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Edit</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>       
      </GridContainer>
    </div>
  );
}
