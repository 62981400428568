
// import DETH_ABI from '../ABI/DETH_ABI.json';
// import Loder from '../assets/images/loader.png'
// import '@metamask/legacy-web3'
// import AudioImg from '../assets/images/AudioImg.jpg'
// import axios from 'axios';
import {network} from "../views/AdminNetwork"

let key = {};
var EnvName = 'production';
let IPFS_IMG = "https://ipfs.infura.io/ipfs"
var networkVersion=''
var BNBProvider="";
// var TradeContract=""
let Back_Url=""
let decimalValues = 1000000000000000000;
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}
if (EnvName === "testlive") {
    // var exchangeAddress = "";
    // var adminaddress = "";
    // var trade = ("").toLowerCase();
    // var API_URL = "https://admin.xdcnft.com";
    // var IMAGE_URL = 'https://admin.xdcnft.com/images/user';
    // var PORT = 2053;
    // Back_Url='https://admin.xdcnft.com'
    // var Front_URL="https://app.xdcnft.com/XDCNFT"
    // BNBProvider             =   "https://rpc.apothem.network";
    // var singleContract    =   "0xC5acF77c1f2bdBbAa75a6331A351BEE006543616".toLowerCase();
    // var multipleContract   =   "0x89A8CcE3F9a84Ac434A6d5a8D92aCA45b8c3729b".toLowerCase();
    // networkVersion      =   '51';    
}
else if (EnvName === "production") {
    // var exchangeAddress = "";
    // var adminaddress = "";
  
    // var trade = ("").toLowerCase();
    // var API_URL = "https://admin.xdcnft.com";
    // var IMAGE_URL = 'https://admin.xdcnft.com/images/user';
    // var PORT = 2053;
    // Back_Url='https://admin.xdcnft.com'
    // var Front_URL="https://app.xdcnft.com/ecafade"
    // BNBProvider             =   "https://erpc.xinfin.network/";
    // var singleContract    =   "0x2385bD067826f0Ac6aD4727cd07D22b84E7aF7E8".toLowerCase();
    // var multipleContract   =   "0x89A8CcE3F9a84Ac434A6d5a8D92aCA45b8c3729b".toLowerCase();
    // networkVersion      =   '50';

    var API_URL = 'https://nftbackend.edaface.com';
    var IMAGE_URL = 'https://nftbackend.edaface.com/images/user';
    // var PORT = 2002;
    Back_Url='https://nftbackend.edaface.com'
    var Front_URL="https://nft-admin.edaface.com/"
    var defaultnet = network.BSC
   var  v1Url = 'https://nftbackend.edaface.com/v1';

}
else if(EnvName === "demo") {
    // var exchangeAddress = "";
    // var adminaddress = "";
    
    // var trade = ("").toLowerCase();

    // var API_URL = 'http://54.242.155.153:2053';
    // var IMAGE_URL = 'http://54.242.155.153:2053/images/user';
    // var PORT = 2053;
    // Back_Url='http://54.242.155.153:2053'
    // var Front_URL="http://54.242.155.153/ADMIN"
    // BNBProvider             =   "https://erpc.xinfin.network/";
    // var singleContract    =   "0x2385bD067826f0Ac6aD4727cd07D22b84E7aF7E8".toLowerCase();
    // var multipleContract   =   "0x89A8CcE3F9a84Ac434A6d5a8D92aCA45b8c3729b".toLowerCase();
    // networkVersion      =   '50';


    // Front_URL = 'https://nft.bimaticz.com';
    // Users_URL = 'http://nft.bimaticz.com:3006/user';
    // Back_URL = 'https://apinft.bimaticz.com';
    // v1Url = 'https://apinft.bimaticz.com/v1';
    // Network = network.BSC


    // var API_URL = 'http://nftdemo.bimaticz.com:3000';
    // var IMAGE_URL = 'http://http://nftdemo.bimaticz.com/images/user';
    // var PORT = 2002;
    // Back_Url='http://nftdemo.bimaticz.com:3000'
    // var Front_URL="http://nftdemo.bimaticz.com/ecafade"
    // var defaultnet = network.BSC


//     var API_URL = 'https://nftbackend.edaface.com';
//     var IMAGE_URL = 'https://nftbackend.edaface.com/images/user';
//     // var PORT = 2002;
//     Back_Url='https://nftbackend.edaface.com'
//     var Front_URL="https://nft-admin.edaface.com/"
//     var defaultnet = network.BSC
//    var  v1Url = 'https://nftbackend.edaface.com/v1';
var API_URL = 'https://apinft.bimaticz.com';
var IMAGE_URL = 'https://apinft.bimaticz.com/images/user';
// var PORT = 2002;
Back_Url='https://apinft.bimaticz.com'
var Front_URL="https://nft.bimaticz.com/ecafade"
var defaultnet = network.ETH
var  v1Url = 'https://apinft.bimaticz.com/v1';



}
else if(EnvName === "local") {
    var API_URL = 'http://localhost:2000';
    var IMAGE_URL = 'http://localhost:2000/images/user';
    var PORT = 2000;
    Back_Url='http://localhost:2000'
    var Front_URL="http://localhost:3001/ecafade"
    var defaultnet = network.BSC
   var v1Url = 'http://localhost:2000';


//console.log("FXGnsdfgn",defaultnet);


}

key = {
    secretOrKey: "",
    Recaptchakey: "",
    // API:`${API_URL}:${PORT}/v1`,
    API:`${API_URL}/v1`,
    IMAGE_URL:IMAGE_URL,
    exchangeAddress:defaultnet.exchangeAddress,
    toasterOption:toasterOption,
    IPFS_IMG:IPFS_IMG,
    networkVersion:defaultnet.networkVersion,
    adminaddress:defaultnet.adminAddress,
    decimalValues:decimalValues,
    Back_Url:Back_Url,
    singleContract:defaultnet.singleContract,
    multipleContract:defaultnet.multipleContract,
    networkVersion:defaultnet.networkVersion,
    BNBProvider:defaultnet.BNBPROVIDER,
    TradeContract:defaultnet.TradeContract,
    Front_URL:Front_URL,
    trade:defaultnet.trade,
    v1Url:v1Url
};

export default key;