
export const network =
{
    ETH: {

        // currencySymbol: 'ETH',
        // tokenSymbol: 'CAKE',
        // tokenABI: { CAKE: DETH_ABI },
        // tokenAddr: { CAKE: "0x565654383Ec9F2d6184b0907cAE7AF150Ead2658".toLowerCase() },
        singleContract: "0x45aF8d34e9bd4bCd283A9dad54941A11fdb1d81E".toLowerCase(),
        // multipleContract: "0x689B803Ae597A35CfDBe1f753E249857F99e05de".toLowerCase(),
        multipleContract: "0xCD3eBeC243dFC28A3918cD3DCA7Ef56A20426a9d".toLowerCase(),
        //trade: ("0x1081E292e0Bda2446a2773C0549d2d1D6bCecFdD").toLowerCase(),
        TradeContract: "0xc3b7719CF89af4700BA4c303186bF0222F6b9184",
        BNBPROVIDER: "https://mainnet.infura.io/v3/be5bdb23c61c4b92b174cb569e57f7b2",
        networkVersion: '1',
        chainId: '0x1',
        Chainid: 1,



    },
    BSC: {

        // currencySymbol: 'BNB',
        // tokenSymbol: 'CAKE',
        // tokenAddr: { CAKE: "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase() },
        // tokenABI: { CAKE: DETH_ABI },
        

        // multipleContract: "0xB3f0e3F02C234e9511df456eF546AF0e48B72Db8".toLowerCase(),
        // multipleContract: "0x0B8709d33ab9B24691B281c12a072036EcE89c3d".toLowerCase(),
        
        // singleContract: "0x83BD31DFa90e3652637b1782a87f80c7EFf48Ef4".toLowerCase(),
        // multipleContract: "0xf3F6100d9Ee7b78cce52cA8229ed13fDcA094A63".toLowerCase(),
      
        //          LIVE

        TradeContract: "0xc3b7719CF89af4700BA4c303186bF0222F6b9184".toLowerCase(),
        singleContract: "0xCD3eBeC243dFC28A3918cD3DCA7Ef56A20426a9d".toLowerCase(),
        multipleContract: "0x80dc293cd13F9d5f2f516A4BeaD02AF839c534b5".toLowerCase(),
        BNBPROVIDER: "https://bsc-dataseed1.binance.org/",
        networkVersion: '56',
        chainId: '0x38',
        Chainid: 56,
        
        //          TESTNET
        
        // TradeContract: "0xE7D77dD9F2511d814EE72817556B7C864C869f79".toLowerCase(),
        // singleContract: "0x8526A6853a6a6ef0a2f1B4d438a6Fc60Ac179971".toLowerCase(),////////polygon
        // multipleContract: "0xd48fa845B28f3587701834Cc3644daDD1935DCce".toLowerCase(),////////polygon
        // BNBPROVIDER: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        // networkVersion: '97',
        // chainId: '0x97',
        // Chainid: 97,

        //trade: ("0x8d008782Bb98DAb61153e651c0B3b6d972C979B0").toLowerCase(),


    }


}